import parsePhoneNumber, { isPossiblePhoneNumber } from 'libphonenumber-js'

export const validatePhone = (phone: string) => {
  if (phone) {
    const phoneNumber = parsePhoneNumber(phone)
    const country = phoneNumber?.country

    const isValid = isPossiblePhoneNumber(phone, 'AU')

    return {
      isValid,
      message:
        '(' +
        (country
          ? country
          : isPossiblePhoneNumber(phone, 'AU')
          ? 'Valid'
          : 'Invalid') +
        ' Number)',
    }
  }
  return null
}
